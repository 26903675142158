@import './vars.css';

/* GENERALS */
*:not(.material-icons){
    font-family: 'Quicksand', sans-serif !important;
}

html{
    scroll-behavior: smooth;
}
.sidebar *{
    transition: all 0.2s linear;
    animation-name: fade-animation;
    animation-duration: 0.2s;
    animation-timing-function: linear;
}

.ng.card:hover{
    box-shadow: 0 .5rem 1.15rem rgba(0,0,0,.1) !important;
    transform: scale(1.035) translate(0px, -5px);
    -webkit-transform: scale(1.035) translate(0px, -5px);
    -moz-transform: scale(1.035) translate(0px, -5px);
    -o-transform: scale(1.035) translate(0px, -5px);
}

body{
    overflow: hidden;
    min-height: 100vh;
}

.cursor-pointer{
    cursor: pointer !important;
}
.cursor-disabled{
    cursor: no-drop !important;
}

.btn-primary-shadow{
    /* box-shadow: 0 1px 20px 1px rgba(255,73,97,.6); */
    box-shadow:0 1px 15px 1px rgba(30, 161, 242, 0.295);
    /* box-shadow: 0 10px 18px 0 rgba(62,57,107,.2);     */
}

.btn-success-shadow{
    box-shadow:0 1px 12px 1px rgba(30, 242, 83, 0.6);
}

.btn-danger-shadow{
    box-shadow: 0 1px 12px 1px rgba(255,73,97,.6);
}

.shadow-sm-btn{
    box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.06);
}

.light-shadow{
    box-shadow:0 .5rem 1rem rgba(0,0,0,.06)!important;
}

.main-content{
    max-height: calc(100vh - var(--navbar-top-height));
    overflow-y: auto;
    width: var(--main-content-width);
    padding: 20px;
    padding-bottom: 3rem !important;
}

@media screen and (min-width: 575px) and (max-width: 650px){
    .pin-rol{
        display: none !important;
    }
}

@media screen and (max-width: 500px){
    .main-content{
        padding-left: 0%;
        padding-right: 0%;
    }
}

@keyframes fade-animation {

    0%{
        opacity: 0;
        position: relative;
        top:5px;
    }

    100%{
        opacity: 1;
        position: relative;
        top:0px;
    }

}


/************************************* 
* FILES
**************************************/
input[type="file"].custom-file-input + label{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/************************************* 
* Scrollbar
**************************************/
@media screen and (min-width: 500px){

.sidebar{
    overflow: hidden !important;
}
.sidebar:hover{
    overflow-y: auto !important;
}

/* width */
.custom-scrollbar::-webkit-scrollbar,body::-webkit-scrollbar,  body *::-webkit-scrollbar {
    width: 7px;
    transition: all 0.2s ease-in-out;
  }
  
  /* Track */
  .custom-scrollbar::-webkit-scrollbar-track, body::-webkit-scrollbar-track, body *::-webkit-scrollbar-track {
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
  }
  .custom-scrollbar::-webkit-scrollbar-track:hover{
  
  background-color: #0000001a;
  } 
  body *::-webkit-scrollbar-track:hover{
  
  background-color: #0000001a;
  } 
  /* Handle */
  .custom-scrollbar::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb, body *::-webkit-scrollbar-thumb {
    background: #00000026; 
    border-radius: 10px;
    transition: all 0.2s ease-in-out;  
  }
  
  /* Handle on hover */
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #0000004d; 
  }
    body *::-webkit-scrollbar-thumb:hover {
    background: #0000004d; 
  }
    body::-webkit-scrollbar-thumb:hover {
    background: #0000004d; 
  }
}

.border-warning.custom-file > *{
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
}

@media screen and (max-width: 460px){
    .noti .badge {
        position: absolute;
        top: -4px;
        left: -5px;
    }
}
  
/************************************* 
* BOOTSTRAP CURN COLORS 
**************************************/
/* Buttons */

/*/ PRIMARY /*/
.btn-primary{
    background-color: var(--curn-blue);
    border-color: var(--curn-blue);
}
.btn-primary:hover, .btn-primary:focus{
    background-color: var(--curn-blue-hover) !important;
    border-color: var(--curn-blue-hover) !important;
}
.btn-outline-primary{
    color: var(--curn-blue);
    border-color: var(--curn-blue);
}
.btn-primary-success:hover, .btn-primary-success:focus{
    background-color: var(--curn-blue-hover) !important;
    border-color: var(--curn-blue-hover) !important;
}
.btn-primary:active, .btn-outline-primary:active{
    background-color: var(--curn-blue-active) !important;
    border-color: var(--curn-blue-active) !important;
}

/*/ SUCCESS /*/
.btn-success{
    background-color: var(--curn-green);
    border-color: var(--curn-green);
}
.btn-success:hover, .btn-success:focus{
    background-color: var(--curn-green-hover) !important;
    border-color: var(--curn-green-hover) !important;
}
.btn-outline-success{
    color: var(--curn-green);
    border-color: var(--curn-green);
}
.btn-outline-success:hover, .btn-outline-success:focus{
    background-color: var(--curn-green-hover) !important;
    border-color: var(--curn-green-hover) !important;
}
.btn-success:active, .btn-outline-success:active{
    background-color: var(--curn-green-active) !important;
    border-color: var(--curn-green-active) !important;
}

/*/ WARNING /*/
.btn-warning{
    background-color: var(--curn-orange);
    border-color: var(--curn-orange);
}
.btn-warning:hover, .btn-warning:focus{
    background-color: var(--curn-orange-hover) !important;
    border-color: var(--curn-orange-hover) !important;
}
.btn-outline-warning{
    color: var(--curn-orange);
    border-color: var(--curn-orange);
}
.btn-outline-warning:hover, .btn-outline-warning:focus{
    background-color: var(--curn-orange-hover) !important;
    border-color: var(--curn-orange-hover) !important;
}
.btn-warning:active, .btn-outline-warning:active{
    background-color: var(--curn-orange-active) !important;
    border-color: var(--curn-orange-active) !important;
}


/* borders */
.border-primary{
    border-color: var(--curn-blue) !important;
}
.border-success{
    border-color: var(--curn-green) !important;
}
.border-warning{
    border-color: var(--curn-orange) !important;
}

/* Backgrounds */
.bg-success{
    background-color: var(--curn-green) !important;
}
.bg-primary{
    background-color: var(--curn-blue) !important;
    color: white;
}
.bg-warning{
    background-color: var(--curn-orange) !important;
}

/* Texts */
a.active{
    color: var(--curn-blue-active) !important
}
.text-warning{
    color: var(--curn-orange) !important
}
.text-primary{
    color: var(--curn-blue) !important
}
.text-success{
    color: var(--curn-green) !important
}


/*Progress bar*/
.progress-bar{
    background-color: var(--curn-blue) !important;
}
.progress-bar.bg-success{
    background-color: var(--curn-green) !important;
}
.progress-bar.bg-warning{
    background-color: var(--curn-orange) !important;
}
.progress-bar.bg-danger{
    background-color: #db3700 !important;
}

/*List Group*/
.list-group-item.active{
    background-color: var(--curn-blue);
    border-color: var(--curn-blue);
    color: white !important;
}

.card{
    border-radius: 15px !important;
}