:root{

/* Sizes */
--navbar-top-height: 70px;    
--sidebar-width: 260px !important;
--main-content-width: calc(100vw - var(--sidebar-width)) !important;

/************************************* 
* CURN COLORS 
**************************************/
--curn-orange: #FF9400;
--curn-orange-hover: rgb(218, 128, 3);
--curn-orange-active: rgb(189, 111, 3);
--curn-blue: #00A19B;
--curn-blue-hover: rgb(2, 133, 128);
--curn-blue-active: rgb(3, 117, 113);
--curn-green: #31ad68;
--curn-green-hover: #268b54;
--curn-green-active: #268b54;
--curn-grey: #4a4a49;
--curn-grey-hover: #313131;
--curn-grey-active: #272727;
}

@media screen and (max-width: 830px){
    :root{
        --main-content-width: 100vw !important;
    }
}
